.code-wrapper{
    position: absolute;
    z-index: 10;
    background-image: linear-gradient(to right, var(--darkBlue), black);
    width: 37%;
    height: calc(100% - 78px);
    top: 78px;
    right: 0;
    transform: translateX(100%);
    transition: all 0.15s ease-out;
    box-shadow: 0 0 20px 5px var(--darkBlue);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top: 0.5px solid gray;
    border-left: 0.5px solid gray;
}

.code-container{
    position: relative;
    height: 100%;
}

.code-logo{
    position: absolute;
    top: 60px;
    transform: translateX(-100%);
    background-color: var(--lightYellow);
    color: black;
    border-top-left-radius: 40%;
    border-bottom-left-radius: 40%;
    padding: 5px 10px;
    padding-right: 10px;
    cursor: pointer;
    border-top: 0.5px solid gray;
    border-left: 0.5px solid gray;
    border-right: 0;
    display: flex;
}

.code-logo > .code-logo-span{
    transition: all 0.2s ease-out;
    font-size: 30px;
}

.code-logo:hover > .code-logo-span{
    scale: 1.15;
}

.code-preview{
    padding: 10px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.code-preview>.code-preview-heading{
    padding: 0;
    margin: 0;
    font-family: Impact, Charcoal, sans-serif;
    font-style: italic;
    font-weight: bolder;
    text-align: center;
}


@media (max-width: 1000px) {
    .code-wrapper{
        /* left: -50px; */
        width: 85%;
    }
}