.login-container,
.signup-container,
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form,
.signup-form,
.otp-form {
  color: white;
  padding: 20px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(70px);
  backdrop-filter: blur(70px);
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  border-top: 0.5px solid grey;
  border-left: 0.5px solid grey;
  animation: fadeInUp 0.5s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form>h2,
.signup-form>h2,
.otp-form>h2 {
  font-family: Impact, Charcoal, sans-serif;
  font-style: italic;
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
  width: 100%;
}

.input-group {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.input-group label {
  display: block;
}

.input-group input {
  width: 100%;
  color: white;
  padding: 10px 0;
  font-size: 17px;
  border: 0;
  border-bottom: 0.5px solid gray;
  box-sizing: border-box;
  background-color: transparent;
}

.input-group input::placeholder {
  color: gray;
}

.input-group input:focus {
  outline: none;
}

.input-group > .password-visibility{
  position: absolute;
  transform: translate(-110%, 10%);
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s linear;
}

.input-group > .password-visibility:hover{
  opacity: 1;
}

.login-form>button,
.signup-form>button,
.otp-form>button {
  width: 95%;
  padding: 7px 0;
  color: var(--lightYellow);
  cursor: pointer;
  transform: skew(-15deg, 0deg);
  /* background-color: var(--lightBlue); */
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.2);
  font-family: Impact, Charcoal, sans-serif;
  font-size: 20px;
  font-style: normal;
  border-radius: 10px;
  border: 2px solid var(--lightYellow);
  transition: all 0.1s linear;
}

.login-form>button:hover,
.signup-form>button:hover,
.otp-form>button:hover {
  scale: 1.05;
  border: 2px solid transparent;
  background-color: var(--lightYellow);
  color: black;
}

.login-link,
.signup-link {
  margin-top: 20px;
  text-align: center;
}

.login-link a,
.signup-link a {
  color: #007BFF;
}

.login-link a:hover,
.signup-link a:hover {
  text-decoration: underline;
}

.backend-message {
  font-style: italic;
  margin: 5px 0;
}

.otp-resend-link {
  text-decoration: underline;
  cursor: pointer;
}

.loading-icon {
  animation: rotateLoading 1s infinite linear;
}
.signup-form>.password-instructions{
  width: 100%;
}

.signup-form>.password-instructions>.password-heading{
  margin: 0;
}

.signup-form>.password-instructions>.password-symbols {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}

.signup-form>.password-instructions>.password-symbols>.password-symbol{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.password-symbol>.ps{
  font-size: 35px;
  opacity: 0.2;
  color: var(--lightYellow);
  transition: all 0.1s ease-in;
}

.password-symbol>.ps-length{
  font-size: 30px;
  margin-bottom: 3px;
}

@keyframes rotateLoading {
  100% {
    transform: rotate(360deg);
  }
}