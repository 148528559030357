/* .home-container{
    position: relative;
} */

.home1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
}

.home1-left {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.home1-left>.welcome-msg {
    font-size: 40px;
    font-family: Impact, Charcoal, sans-serif;
    font-style: italic;
    font-weight: bolder;
    color: white;
    animation: fadeInUp 0.5s ease-out;  
    text-align: center;
}

.text2{
    font-size: 75px;
}

.text1>.welcome-msg-preview,
.text1>.welcome-msg-code {
    color: var(--lightBlue);
}

.home1-right {
    flex: 1;
}

.home1-right>.welcome-image {
    width: 100%;
    border-radius: 17px;
    box-shadow: 0 7px 30px var(--darkBlue);
    animation: fadeInUp 0.5s ease-out;
}

.develop-button{
    transform: skew(-15deg, 0deg);
    /* background-color: var(--lightBlue); */
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 25px;
    font-style: normal;
    border-radius: 10px;
    display: inline-block;
    border: 2px solid var(--lightYellow);
    transition: all 0.1s linear;
    margin-top: 5rem;
}

.develop-button:hover{
    scale: 1.1;
    border: 2px solid transparent;
}

.develop-button>a{
    color: var(--lightYellow);
    text-decoration: none;
    padding: 10px 20px;
}

.develop-arrow{
    height: 100%;
    padding: 7px 0 10px 3px;
    transform: translateY(1px);
    transition: transform 0.1s ease-in-out;
    font-weight: bolder;
}

.develop-button:hover .develop-arrow{
    transform: translate(3px, 1px);
}

.about, .contact{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-heading, .contact-heading{
    font-size: 40px;
    font-family: Impact, Charcoal, sans-serif;
    font-style: italic;
    font-weight: bolder;
}

.about-desc{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 30px;
}

.about-codesip{
    font-size: 25px;
    font-family: Impact, Charcoal, sans-serif;
    font-style: italic;
    font-weight: bolder;
}

.contact-con{
    margin-top: 50px;
    display: flex;
}

.contact-con>a{
    color: var(--lightYellow);
}
.contact-con>a>i{
    font-size: 60px;
    margin: 0 50px;
    transition: all 0.2s ease-in-out;
}

.contact-con>a:hover > .fa-brands{
    scale: 1.1;
}

@media (max-width: 768px) {
    .home1{
        flex-direction: column;
    }
    .home1-left>.welcome-msg {
        font-size: 30px;
    }
    .text2{
        font-size: 50px;
    }
    .contact-con{
        flex-direction: column;
    }
    .about-desc{
        font-size: 25px;
    }
    .contact-con>a>i{
        margin: 20px 0;
    }
}