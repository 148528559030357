* {
  --lightYellow: #FDFFE2;
  --lightBlue: #3e77cc;
  --lightDarkBlue: #5A72A0;
  --darkBlue: #1A2130;
  --fontLight: white;
  --fontDark: black;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--fontLight);
}

.container {
  margin: 0 auto;
  padding: 0 5%;
  width: 100vw;
  box-sizing: border-box;
  max-width: 1800px;
}

.title {
  font-family: Impact, Charcoal, sans-serif;
  font-style: italic;
  font-weight: bolder;
  display: flex;
  color: var(--fontLight);
}

.title>p {
  margin: 0;
}

.title-bracket-left-con,
.title-bracket-left-con-ob,
.title-bracket-right-con,
.main-title-con {
  position: relative;
  width: 18px;
  height: 35px;
}

.main-title-con {
  width: 110px;
}

.title-bracket-left {
  position: absolute;
  animation: moveLeft 2s ease;
}

.title-bracket-left-ob {
  transform-origin: 70% 50%;
  position: absolute;
  animation: rotateOb 2s ease;
  color: var(--lightBlue);
}

.title-bracket-right,
.main-title {
  position: absolute;
  animation: moveRight 2s ease;
}

@keyframes rotateOb {
  10% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

@keyframes moveLeft {

  0%,
  10%,
  100% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-15px);
  }
}

@keyframes moveRight {

  0%,
  15%,
  100% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(10px);
  }
}

.colored-bg,
.blurred-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  /* bottom: 0; */
  /* background-color: var(--darkBlue); */
}

.colored-bg {
  z-index: -10;
  background-color: black;
}

.ball-con1,
.ball-con2 {
  position: absolute;
}

.ball-con1 {
  top: 50%;
  left: 50%;
  transform: translate(-100%, -50%);
}

.ball-con2 {
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ball1 {
  width: 20rem;
  height: 20rem;
  background-color: var(--lightDarkBlue);
  border-radius: 50%;
}

.ball4 {
  width: 15rem;
  height: 15rem;
  background-color: var(--darkBlue);
  border-radius: 50%;
}

.ball2 {
  width: 35rem;
  height: 35rem;
  background-color: var(--lightYellow);
  border-radius: 50%;
}

.ball3 {
  width: 55rem;
  height: 55rem;
  background-color: var(--lightBlue);
  border-radius: 50%;
}

.blurred-bg {
  z-index: -9;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  background-color: rgba(0, 0, 0, 0.6);
}

.header {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  color: white;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header>h1 {
  margin: 1rem;
}

.hamburger-menu {
  display: none;
  margin: auto 0;
  /* Only display this in mobile view */
  cursor: pointer;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: var(--lightYellow);
  margin: 5px 0;
  transition: 0.4s;
}

/* Transform bars into X when open */
.hamburger-menu .bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-menu .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger-menu .bar.open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.navigation-menu {
  margin: auto 0;
  z-index: 1;
}

.navigation-menu.open {
  animation: fadeInNav 0.2s ease-in;
}

@keyframes fadeInNav {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navigation-menu>ul {
  display: flex;
}

.navigation-menu>ul>li {
  list-style: none;
  padding: 0 1rem;
  transition: all 0.2s ease-out;
}

.navigation-menu>ul>li:hover {
  text-shadow: 0px 0px 20px white;
  transform: scale(1.1);
}

.navigation-menu>ul>li a {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
}


.profile-menu {
  cursor: default;
}

.dropdown-content {
  display: none;
  border-radius: 0.5rem;
  position: absolute;
  left: -100%;
  background-color: var(--darkBlue);
  border-top: 0.5px solid white;
  border-left: 0.5px solid white;
  color: var(--lightYellow);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
  min-width: 160px;
  z-index: 1999;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  text-shadow: 0px 0px;
  display: block;
  text-align: left;
}

.dropdown-content .user-name-con {
  padding: 12px 16px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: bolder;
}

.dropdown-content a:hover {
  text-shadow: 0px 0px 20px white;
}

.profile-menu:hover .dropdown-content {
  display: block;
}

/* Notifications */
.notification-con {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.notification {
  padding: 10px 15px;
  background-color: rgb(38, 38, 38);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  min-width: 300px;
  margin: 0 10px 10px 0;
  position: relative;
  animation: fadeNotification 5s linear forwards;
  transition: all 0.2s linear;
}

@keyframes fadeNotification {
  0%{
    opacity: 0;
  }
  5%{
    opacity: 1;
  }
  95%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

/* For all the pages */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .title-bracket-left-con,
  .title-bracket-left-con-ob,
  .title-bracket-right-con,
  .main-title-con {
    width: 15px;
    height: 35px;
  }

  .main-title-con {
    width: 105px;
  }

  .hamburger-menu {
    display: block;
  }

  .navigation-menu {
    background-image: linear-gradient(to bottom, var(--darkBlue), black);
    position: absolute;
    right: 0;
    top: 75px;
    width: 100%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: none;
  }

  .navigation-menu.open {
    display: block;
  }

  .navigation-menu>ul {
    flex-direction: column;
  }

  .navigation-menu>ul>li {
    padding: 0.5rem 0;
  }

  .navigation-menu>ul>li a {
    color: white;
  }

  .dropdown-content {
    display: block;
    position: static;
    background-color: transparent;
    box-shadow: 0 0 0;
    border: 0;
  }

  .dropdown-content a {
    color: var(--darkBlue);
    text-decoration: none;
    text-shadow: 0px 0px;
    display: block;
    text-align: left;
    padding: 0.5rem 0;
  }

  .dropdown-content .user-name-con {
    font-size: 20px;
    padding: 12px 0 5px 0;
    color: var(--lightYellow);
  }

  .account_logo {
    display: none;
  }
}