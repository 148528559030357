.chat-page-container {
  display: flex;
  height: 100vh;
  flex-direction: row-reverse;
  position: relative;
  overflow: hidden;
}

.user-chat-container {
  flex-basis: 35%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.user-chat {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 70px;
  scroll-behavior: smooth;
  position: relative;
}

.welcome-chat-msg {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 500;
  font-size: 1.1rem;
  /* text-shadow: 0.5px 0.5px rgb(15, 15, 15); */
  color: var(--lightYellow);
}

.welcome-q,
.welcome-q-1 {
  margin: 0;
}

.welcome-q-2 {
  font-size: 1.5rem;
  font-family: Impact, Charcoal, sans-serif;
  font-style: italic;
  font-weight: bolder;

  /* text-shadow:  0 0;
  background-image: linear-gradient(to left, #ff1515, rgb(0, 187, 255));
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; */
}

.message-box {
  display: flex;
  border-radius: 0.8rem;
  margin-bottom: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#user-input {
  flex-grow: 1;
  padding: 15px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom-left-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.2);
  border-top: 0.5px solid grey;
  border-left: 0.5px solid grey;
  color: white;
}

.build-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  cursor: pointer;
  font-size: large;
  border: 1px solid transparent;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(129, 179, 255, 0.425);; 
  border-top: 0.5px solid grey;
  color: rgb(176, 176, 176);
}

.build-button:hover>.build-button-span {
  transition: all 0.2s ease-out;
  scale: 1.2;
  color: var(--lightYellow);
}

.website-preview-container {
  flex-basis: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-frame-wrapper {
  width: 95%;
  height: 90vh;
  margin-top: 80px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.website-frame {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  background-color: white;
  position: absolute;
}

.preview-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
  /* filter: blur(10px); */
  border-radius: 10px;
  z-index: 11;
  font-size: 20px;
  font-weight: bolder;
}

/* .preview-loading::before{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: flex;
  content: '';
  filter: blur(100px);
  justify-content: center;
  align-items: center;
} */


/* Message Box CSS */
.chat-con {
  margin: 0.3rem 0;
  display: flex;
  color: black;
}

.chat-message-wrapper {
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  -webkit-backdrop-filter: blur(70px);
  backdrop-filter: blur(70px);
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  color: white;
  border-top: 0.5px solid grey;
  border-left: 0.5px solid grey;
  max-width: 80%;
  margin: 0.5rem 0;
  animation: scaleUp 0.4s ease-out;
  transform-origin: "right center";
}

.chat-message-wrapper-left {
  background-color: rgba(255, 255, 255, 0.2);
  border-top: 0.5px solid white;
  border-left: 0.5px solid white;
  transform-origin: "left center";
}

@keyframes scaleUp {
  0% {
    scale: 0.99;
    opacity: 0;
    box-shadow: none;
  }

  60% {
    scale: 1.05;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  }

  100% {
    scale: 1;
    opacity: 1;
    box-shadow: none;
  }
}

/* For typing animation */
.typing-indicator {
  background-color: rgba(255, 255, 255, 0.2);
  transform-origin: left;
  display: none;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}

.typing-indicator .dot {
  width: 0.6rem;
  height: 0.6rem;
  margin-right: 4px;
  background-color: #333;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {

  0%,
  80%,
  100% {
    opacity: 0;
    scale: 0.7;
  }

  40% {
    opacity: 1;
    scale: 1.2;
  }
}


@media (max-width: 1000px) {
  .chat-page-container {
    flex-direction: column-reverse;
  }

  .website-preview-container {
    flex-basis: 30%;
    height: 30%;
    margin-top: 80px;
  }

  .website-frame-wrapper {
    width: 90%;
    height: 100%;
    margin-top: 0;
  }

  .user-chat-container {
    flex-basis: 70%;
    height: 70%;
  }

  .user-chat {
    margin: 0;
  }
}